@import "./variables.scss";

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flexRow {
  display: flex;
  justify-content: center;
}

@mixin layout {
  width: $width100;
  height: auto;
  align-self: center;
  @include flexCol;
}

@mixin commonWidth {
  align-self: center;
  width: 80%;
  max-width: 80%;
  @include respond-to("medium") {
    max-width: 462px;
  }
  @include respond-to("large") {
    max-width: 610px;
  }
}

@function toRem($px) {
  @return calc(($px / 16) * 1rem);
}

@function cols($num, $total: $layout-columns) {
  @return calc(($num / $total) * 100%);
}

@mixin maxWidth($width: $max-width) {
  max-width: $width;
  margin: 0 auto;
  width: 90%;
}

@mixin fontSize($fontsize, $leading: null) {
  font-size: calc(($fontsize / 16) * 1rem);
  @if ($fontsize and $leading) {
    line-height: ceil(($leading / $fontsize) * 10000) / 10000;
  }
}
@mixin underLineOnHover {
  &:hover {
    cursor: pointer;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $h-green;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}