@import "../../styles/app.scss";

.nav {
    @include flexRow;
    background-color: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    box-sizing: border-box;
    width: 100%;
    height: auto;
    min-width: 320px;
    color: $white;
    position: fixed;
    top: 0;
    padding: toRem(12) toRem(16);
    z-index: 10;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.5s linear;
    @include respond-to("medium") {
        padding: 0 toRem(20);
        height: 64px;
    }

    @include respond-to("large") {
        padding: 0 toRem(60);
        height: 80px;
    }

    &--bg{
        background-color: $h-green;
    }

    &__close {
        border: none;
        background: none;
        display: none;
        visibility: hidden;
        opacity: 0;
        transform: scale(0.5);
        transform-origin: center;
        &:hover {
            cursor: pointer;
        }
        &:hover .nav__close__stroke {
            stroke: $h-green;
        }
        &--bg{
            display: inline-block;
            &:hover .nav__close__stroke {
                stroke: $h-dark-green;
            }
        }
        &__icon {
            @include respond-to("medium") {
                height: 16px;
                width: 16px;
                margin-right: 2px;
            }

            @include respond-to("large") {
                height: 31px;
                width: 31px;
                margin-right: 4px;
            }
        }
        &__stroke{
            stroke: $white;
            stroke-width: 5;
            stroke-linecap: round;
        }
    }

    &__logo {
        align-self: center;
        &:hover .logo-nav__path {
            fill: $h-green;
        }
        &--bg{
            &:hover .logo-nav__path {
                fill: $h-dark-green;
            }
        }
    }

    &__links {
        position: relative;
        @include flexRow;
        align-items: center;
        column-gap: toRem(32);
        // @include respond-to("large") {
        //     column-gap: toRem(60);
        // }
        &__list {
            // visibility: hidden;
            // transform: translateX(30px);
            // opacity: 0;
            @include flexRow;
            align-items: center;
            column-gap: toRem(32);
            @include respond-to("large") {
                column-gap: toRem(60);
            }
        }
    }

    &__item {
        @include flexCol;
        white-space: nowrap;
        align-items: center;
        align-self: baseline;
        &__link {
            align-self: center;
            text-decoration: none;
            color: $white;
            @extend %font-bold;
            @include flexRow;
            gap: 10px;
            align-items: center;
            @include fontSize(12);

            @include respond-to("large") {
                gap: 12px;
                @include fontSize(16);
            }
           
            &:hover {
                color: $h-green;
            }
            &:hover .filmIcon-nav__path {
                fill: $h-green;
            }
            &--bg{
                &:hover {
                    color: $h-dark-green;
                }
                &:hover .filmIcon-nav__path {
                    fill: $h-dark-green;
                }
            }
            &-cs {
                pointer-events: none;
            }
            &--film {
                @include fontSize(8);
                margin-bottom: 4px;
                @include respond-to("large") {
                    @include fontSize(10);
                }
            }
            &--cs {
                align-self: flex-start;
                opacity: 0.5;
                padding-left: 30px;
                @include fontSize(10);
                padding-top: 2px;

                @include respond-to("large") {
                    padding-top: 6px;
                    padding-left: 36px;
                    @include fontSize(12);
                }
            }
         
        }
    }

    &__menu {
        position: absolute;
        background: none;
        border: none;
        align-self: center;
        right: 16px;
        &:hover {
            cursor: pointer;
        }
        @include respond-to("medium") {
            right: 20px;
            visibility: hidden;
            &:hover .nav__menu__icon {
                fill: $h-green;
            }
        }
        @include respond-to("large") {
            right: 60px;
        }
        &--bg{
            @include respond-to("medium") {
                &:hover .nav__menu__icon {
                    fill: $h-dark-green;
                }
            }
        }
        &__icon {
            transform-origin: center;
            width: toRem(22);
            height: toRem(12);
            fill: $white;
            @include respond-to("large") {
                width: toRem(40);
                height: toRem(25);
            }
        }
    }
}
