@import "../../styles/app.scss";

.poll {
    width: $width100;
    color: $white;
    @include flexCol;
    @include respond-to("medium") {
        flex-direction: row;
        justify-content: flex-start;
    }
    &-0 {
        background: $h-navy;
    }
    &-1 {
        background: #007481;
        flex-direction: column-reverse;
        justify-content: flex-end;
        @include respond-to("medium") {
            flex-direction: row-reverse;
          
        }
    }
    &-2 {
        background: #af0061;
    }
    &__result {
        align-self: center;
        width: $widthSmall;
        padding: 60px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        @include respond-to("medium") {
            width: 50vw;
            padding: 0 0 0 min(40px, 5vw);
        }
        @include respond-to("large") {
            padding-left: min(5vw, 80px);
        }
        &-contents {
            @include respond-to("medium") {
                max-width: 425px;
            }
            @include respond-to("large") {
                max-width: 526px;
            }
        }
        h2 {
            font-size: 36px;
            margin-top: 0;
            margin-bottom: 20px;
            @extend %font-bold;
            @include respond-to("medium") {
                font-size: 24px;
            }
            @include respond-to("large") {
                font-size: 48px;
                margin-bottom: 40px;
            }
        }
        h3 {
            font-size: 18px;
            line-height: 24px;
            margin-top: 8px;
            margin-bottom: 24px;
            @extend %font-bold;
            @include respond-to("medium") {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 20px;
            }
            @include respond-to("large") {
                font-size: 24px;
                line-height: 32px;
                margin-top: 10px;
            }
        }
        &__small {
            color: rgba(255, 255, 255, 0.8);
            font-size: 11px;
            @include respond-to("medium") {
                font-size: 10px;
            }
            @include respond-to("large") {
                font-size: 12px;
            }
        }
        p {
            margin-top: 3px;
            margin-bottom: 10px;
            @include respond-to("medium") {
                margin-top: 7px;
                margin-bottom: 13px;
            }
            @include respond-to("large") {
                margin-top: 2px;
                margin-bottom: 14px;
            }
        }

        &__graph {
            width: $width100;
            position: relative;
            @include flexRow;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
            @include respond-to("large") {
                gap: 14px;
            }
            &__bar {
                width: 75%;
                height: 20px;
                border: 0.655px solid rgba(255, 255, 255, 0.5);
                  @include respond-to("medium") {
                    height: 16px;
            }
                @include respond-to("large") {
                    height: 28px;
                }
                &-fill {
                    width: 0%;
                    position: absolute;
                    background-color: $white;
                }
            }

            &__num {
                font-size: 26px;
                @extend %font-bold;
                @include respond-to("medium") {
                    font-size: 22px;
                }
                @include respond-to("large") {
                    font-size: 40px;
                }
            }
        }
    }
    &__photo {
        width: 100vw;
        height: 108vw;
        overflow: hidden;
        background-size:115% auto;
        background-position: center;
        background-repeat: no-repeat;
        @include respond-to("medium") {
            width: 50vw;
            height: 54vw;
        }
        &-0 {
            background-image: url("../../assets/poll1.jpg");
        }
        &-1 {
            background-image: url("../../assets/poll2.jpg");
        }
        &-2 {
            background-image: url("../../assets/poll3.jpg");
        }
        &-odd {
            border-radius: 0px 0px 0px 120px;
            @include respond-to("medium") {
                border-radius: 0px 90px 0px 0px;
            }
            @include respond-to("large") {
                border-radius: 0px 180px 0px 0px;
            }
        }
        &-even {
            border-radius: 0px 120px 0px 0px;
            @include respond-to("medium") {
                border-radius: 0px 0px 0px 90px;
            }
            @include respond-to("large") {
                border-radius: 0px 0px 0px 180px;
            }
        }
    }
}
