@import "../../styles/App.scss";

.filmIcon {
    align-self: center;
    height: auto;
    &-nav{
        width: 20px;
        @include respond-to("large") {
            width: 24px;
        }
        &__path{
            fill:$white;
        }
    }
    &-film{
        width: 38px;
        @include respond-to("medium") {
          width: 30px;
        }
        @include respond-to("large") {
          width: 50px;
        }
        &__path{
            fill:$h-dark-green;
            @include respond-to("medium") {
                fill:$white;
              }
        }
    }
    &-menu{
        width: 41.25px;
        margin-bottom: 12px;
        &__path{
            fill:$h-dark-green;
        }
    }
}