@import "../../styles/app.scss";

.hero {
    width: $width100;
    overflow: hidden;
    min-width: 350px;
    max-height: 120vw;
    min-height: 450px;
    height: 90vh;
    position: relative;
    @include flexRow;
    @include respond-to("medium") {
        height: 56.25vw;
        min-height: 56.25vw;
    }

    h1 {
        width: 208px;
        align-self: center;
        text-align: center;
        font-size: 36px;
        @extend %font-bold;
        @include respond-to("medium") {
            width: fit-content;
        
        }
        @include respond-to("large") {
            font-size: 72px;
        }
    }
    &__copy {
        width: $width100;
        padding: 48px 0;
        align-self: center;
        background: $h-green;
        color: $h-dark-green;
        @include flexRow;
        @include respond-to("large") {
            padding: 90px 0;
        }
        p {
            width: $widthSmall;
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            @include respond-to("medium") {
                font-size: 12px;
                line-height: 18px;
                width: 450px;
            }
            @include respond-to("large") {
                width: 830px;
                font-size: 20px;
                line-height: 32px;
            }
        }
    }
}
