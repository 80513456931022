@import "./variables.scss";
@import "./mixins.scss";
@import "./fonts.scss";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.app {
  width: $width100;
  min-width: 320px;
  color: $white;
  overflow: hidden;
  position: relative;
  @include flexCol;
  @extend %font-regular;
}

.videoloop {
  align-self: center;
  width: $width100;
  object-fit: cover;
  &-m {
    height: 100%;
    &-hero {
      position: absolute;
      display: block;
      z-index: -1;
     
    }
    @include respond-to("medium") {
      display: none;
    }
    &-hero {
      position: absolute;
      z-index: -1;
    }
  }
  &-d {
    display: none;
    @include respond-to("medium") {
      position: absolute;
      display: block;
      z-index: -1;
      height: 56.25vw;
      width: 100%;
    }
  }
}

.text--bold {
  @extend %font-bold;
}
.text--green {
  color: $h-green;
}

.text--animate {
  opacity: 0;
  transform: translateY(50px);
  &-transition {
    transition: all 0.7s linear;
  }
}
