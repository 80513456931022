@import './fonts.scss';

//colors-------------------------------------
$white: #ffffff;
$black: #000000;
$h-green:#78BE20;
$h-cont-green:#5C9A1B;
$h-navy:#003057;
$h-dark-green: #114A21;
$text-gray: #3A3B3D;


//width--------------------------------------
$widthSmall: 85vw;

//breakpoints--------------------------------
$breakpoints: (
  'small': (
    min-width: 481px
  ),
  'medium': (
    min-width: 768px
  ),
  'large': (
    min-width: 1200px
  ),
  'xlarge': (
    min-width: 1440px
  ),
  '2xlarge': (
    min-width: 1920px
  )
) !default;

$width100: 100%;

