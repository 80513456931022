@import "../../styles/app.scss";

.button {
  height: 44px;
  width: fit-content;
  border: none;
  font-size: 20px;
  @extend %font-bold;
  transition: background-color 0.3s;
  padding: 0px 30px;
  border-radius: 44px;
  white-space: nowrap;
  @include respond-to("medium") {
    height: 32px;
    font-size: 15px;
    border-radius: 32px;
    padding: 0px 20px;
  }
  @include respond-to("large") {
    height: 60px;
    font-size: 28px;
    border-radius: 60px;
    padding: 0px 36px;
  }
  &:hover {
    cursor: pointer;
  }
  // &__link {

  //   &-film{
  //     color:$h-dark-green;
  //     &:hover {
  //       color: $h-green;
  //       @include respond-to("medium") {
  //         color: $h-dark-green;
  //       }
  //     }
  //   }
  // }
  &-cta {
    align-self: center;
    background-color: $h-dark-green;
    &:hover {
      background-color: rgba(17, 74, 33, 0.7);
    }
    a {
      text-decoration: none;
      color: $white;
    }
  }
  &-film {
    background: $white;
    border-radius: 44px;
    padding: 0 26.5px;
    color: $h-dark-green;
    &:hover {
      color: $h-green;
      background-color: $h-dark-green;
      @include respond-to("medium") {
        background-color: $h-green;
        color: $h-dark-green;
      }
    }
    // &:hover .button__link-film {
    //   color: $h-green;
    //   @include respond-to("medium") {
    //     color: $h-dark-green;
    //   }
    // }
  }
  &-share {
    visibility: hidden;
    position: fixed;
    z-index: 3;
    top: calc(100vh - 100px);
    align-self: center;
    color: $h-dark-green;
    background-color: $white;
    border-radius: 0;
    pointer-events: none;
    @include respond-to("medium") {
      background-color: $h-green;
    }
  }
}
