@import "../../styles/App.scss";

.film {
  position: relative;
  @include flexCol;
  @include respond-to("medium") {
    flex-direction: row;
  }
  &__buttons {
    gap: 20px;
    width: fit-content;
    @include flexRow;
    @include respond-to("medium") {
      gap: 16px;
    }
    @include respond-to("large") {
      gap: 20px;
    }
    &-share {
      @include flexRow;
      align-items: center;
      background: none;
      border: none;
      color: $white;
      gap: 9px;
      opacity: 0.8;
      font-size: 17px;
      @extend %font-bold;
      &:hover {
        cursor: pointer;
        color: $h-dark-green;
        @include respond-to("medium") {
          color: $h-green;
        }
      }
      &:hover .film__buttons-share__icon-fill {
        fill: $h-dark-green;
        @include respond-to("medium") {
          fill: $h-green;
        }
      }
      @include respond-to("medium") {
        font-size: 12px;
        gap: 6.5px;
      }
      @include respond-to("large") {
        font-size: 24px;
        gap: 12px;
      }
      &__icon {
        align-self: center;
        width: 30px;
        height: auto;
        @include respond-to("medium") {
          width: 22px;
        }
        @include respond-to("large") {
          width: 41px;
        }
      }
    }
  }
  &__eyebrow {
    text-transform: capitalize;
    font-size: 16px;
    @include respond-to("medium") {
      font-size: 10px;
    }
    @include respond-to("large") {
      font-size: 16px;
    }
  }

  &__close {
    position: absolute;
    z-index: 9;
    &:hover {
      cursor: pointer;
    }

    &:hover .film__close--stroke {
      stroke: $h-green;
    }
    &:hover .film__close--fill {
      fill: $h-green;
    }

    top: calc(50% - 28.125vw - 30px);
    right: 10px;
    width: 25px;
    height: 25px;

    @include respond-to("medium") {
      align-self: center;
      top: calc(50% - 28.125vw + 5px);
      width: min(5vw, 42px);
      height: min(5vw, 42px);
    }

    @include respond-to("large") {
      top: toRem(20);
      right: toRem(20);
    }
  }
}

.film-wrapper {
  background: $h-green;
  padding-top: 30px;
  padding-bottom: 60px;
  width: $width100;
  color: $h-dark-green;
  margin-top: -2px;
  @include flexRow;
  @include respond-to("medium") {
    // background: linear-gradient(52.27deg, rgba(0, 0, 0, 0.6) 23.66%, rgba(0, 0, 0, 0) 73.88%);
    justify-content: flex-start;
    margin-top: 0;
    background: none;
    height: 56.25vw;
    color: $white;
    padding: 0;
  }

  .film-content {
    align-self: center;
    width: $widthSmall;
    // @include tileContent;
    @include respond-to("medium") {
      margin-left: 6.25vw;
      width: 275px;
    }
    @include respond-to("large") {
      width: 516px;
    }
    &__head {
      display: flex;
      justify-content: flex-start;
      gap:16px;
      @include respond-to("medium") {
        gap:12px;
      }
      @include respond-to("large") {
        gap:20px;
      }
    
    }
    h2 {
      white-space: nowrap;
      font-size: 32px;
      margin: 0;
      margin-top: 4px;
      @extend %font-bold;
      @include respond-to("medium") {
        font-size: 24px;
        margin-top: 5px;
      }
      @include respond-to("large") {
        font-size: 48px;
        margin-top: 10px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      @include respond-to("medium") {
        font-size: 12px;
        line-height: 18px;
      }
      @include respond-to("large") {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}

.film__filmModal {
  @include flexRow;
  align-self: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  background: black;
  backdrop-filter: blur(10px);
  top: 0;
  visibility: hidden;

  @include respond-to("medium") {
    height: 56.25vw;
  }

  .film-video {
    align-self: center;
    width: 100%;
    height: 56.25vw;
  }
}
