@import "../../styles/app.scss";

.infographic {
    background: $h-dark-green;
    width: $width100;
    @include flexCol;

    @include respond-to("medium") {
        gap: 32px;
        padding: 64px 0;
    }
    @include respond-to("large") {
        gap: 46px;
        padding: 120px 0;
    }
    &__row {
        align-self: center;
        width: $widthSmall;
        @include flexCol;
        @include respond-to("medium") {
            flex-direction: row;
            width: 556px;
        }
        @include respond-to("large") {
            width: 1044px;
        }
        &--top {
            @include respond-to("medium") {
                border-bottom: 1px solid $h-green;
                padding-bottom: 32px;
            }
            @include respond-to("large") {
                padding-bottom: 46px;
            }
        }
    }
    &__item {
        width: $width100;
        @include flexCol;
        border-bottom: 1px solid $h-green;
        padding: 60px 0;
        @include respond-to("medium") {
            padding: 0;
            border-bottom: none;
        }
        &--left {
            @include respond-to("medium") {
                padding-right: 32px;
                border-right: 1px solid $h-green;
            }
            @include respond-to("large") {
                padding-right: 60px;
            }
        }
        &--right {
            @include respond-to("medium") {
                padding-left: 32px;
            }
            @include respond-to("large") {
                padding-left: 60px;
            }
        }
        &-heart {
            @include respond-to("medium") {
                width: 235px;
            }
            @include respond-to("large") {
                width: 441px;
            }
        }
        &-globe {
            @include respond-to("medium") {
                width: 322px;
            }
            @include respond-to("large") {
                width: 604px;
            }
        }
        &-work {
            @include respond-to("medium") {
                width: 272px;
            }
            @include respond-to("large") {
                width: 510px;
            }
        }
        &-active {
            border-bottom: none;
            @include respond-to("medium") {
                width: 285px;
            }
            @include respond-to("large") {
                width: 535px;
            }
        }
        &__bottom {
            // align-self: center;
            @include flexCol;
            padding-top: 32px;
            @include respond-to("medium") {
                align-items: center;
                flex-direction: row;
                padding-top: 16px;
                gap: 16px;
            }
            @include respond-to("large") {
                padding-top: 30px;
                gap: 30px;
            }
        }
        &__icon {
            height: auto;
            &-heart {
                svg {
                    width: 237px;
                    padding-bottom: 32px;
                    @include respond-to("medium") {
                        width: 137px;
                        padding-bottom: 16px;
                    }
                    @include respond-to("large") {
                        width: 258px;
                        padding-bottom: 30px;
                    }
                }
            }
            &-globe {
                svg {
                    width: 171px;
                    @include respond-to("medium") {
                        width: 109px;
                    }
                    @include respond-to("large") {
                        width: 220px;
                    }
                }
            }
            &-work {
                svg {
                    width: 192px;
                    @include respond-to("medium") {
                        width: 93px;
                    }
                    @include respond-to("large") {
                        width: 177px;
                    }
                }
            }
            &-active {
                align-self: center;
                svg {
                    width: 220px;
                    padding-bottom: 31px;
                    @include respond-to("medium") {
                        width: 118px;
                        padding-bottom: 16px;
                    }
                    @include respond-to("large") {
                        width: 285px;
                        padding-bottom: 25px;
                    }
                }
            }
        }

        &__text {
            opacity: 0;
            transform: translateY(50px);
            h3 {
                margin: 0;
                font-size: 30px;
                color: $h-green;
                @extend %font-bold;
                @include respond-to("medium") {
                    font-size: 18px;
                }
                @include respond-to("large") {
                    font-size: 36px;
                }
            }
            p {
                color: $white;
                font-size: 16px;
                line-height: 20px;
                @extend %font-bold;
                @include respond-to("medium") {
                    font-size: 10px;
                    line-height: 14px;
                }
                @include respond-to("large") {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            &--nmt {
                margin-top: 0;
            }
            cite {
                display: block;
                @extend %font-regular;
                font-size: 10px;
                line-height: 18px;
                @include respond-to("medium") {
                    font-size: 8px;
                    line-height: 14px;
                }
                @include respond-to("large") {
                    font-size: 10px;
                    line-height: 18px;
                }
            }
            &__num {
                @extend %font-heavy;
                overflow: hidden;
                font-size: 138px;
                letter-spacing: -0.9px;
                @include respond-to("medium") {
                    font-size: 80px;
                    letter-spacing: -0.5px;
                }
                @include respond-to("large") {
                    font-size: 150px;
                    letter-spacing: -1px;
                }
                &--small {
                    font-size: 64px;
                    @include respond-to("medium") {
                        font-size: 36px;
                    }
                    @include respond-to("large") {
                        font-size: 70px;
                    }
                }
            }
        }
    }
}

.stroke-white {
    fill: none;
    stroke: #ffffff;
    stroke-miterlimit: 10;
}
.stroke-4 {
    stroke-width: 4;
}
.stroke-5 {
    stroke-width: 5;
}
.stroke-6 {
    stroke-width: 6;
}
.stroke-round {
    stroke-linecap: round;
    stroke-linejoin: round;
}
.stroke-dash {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
}
.stroke-green {
    stroke: $h-green;
}
.fill-green {
    fill: $h-green;
}
.fill-dkgreen {
    fill: $h-dark-green;
}
.fill-white {
    fill: $white;
}
.animation-movedown {
    transform: translateY(-200px);
}
.opacity-0 {
    opacity: 0;
}
.stroke-dash-350 {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
}
.animation-movein {
    transform: translateX(200px);
}
