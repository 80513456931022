@import "../../styles/App.scss";

.mobileMenu {
    @include layout;
    width: 100%;
    min-width: 320px;
    position: fixed;
    top: 0;
    z-index: 9999;
    transform: translateX(max(100%, 320px));
    background: $h-green;
    &__contents {
        @include flexCol;
        width: 100%;
        padding-top: 10%;
        height: 100vh;
        box-sizing: border-box;
        position: relative;
        align-self: center;
        justify-content: space-evenly;
        & ul {
            @include flexCol;
            align-self: center;
            padding: 0;
        }

        &__close {
            @include flexRow;
            margin: toRem(12) toRem(16);
            z-index: 3;
            border: none;
            background-color: transparent;
            position: absolute;
            box-sizing: border-box;
            top: 0;
            right: 0;

            &:hover {
                cursor: pointer;
            }

            &-img {
                align-self: center;
                width: toRem(42);
                height: toRem(42);
            }
        }

        &__item {
            @include flexCol;
            justify-content: flex-start;
            padding: 20px;
            color: $h-dark-green;
            &-container {
                @include flexCol;
                align-self: center;
            }
            &__episode {
                align-self: center;
                font-size: 16px;
            }
            &__link {
                @extend %font-bold;
                @include flexCol;
                font-size: 26px;
                align-self: center;
                color: $h-dark-green;
                text-decoration: none;
            }
            &__cs {
                align-self: center;
                opacity: 0.5;
                font-size: 20px;
                padding-top: 6px;
            }
        }

        &__logo {
            align-self: center;
            width: auto;
            height: toRem(40);
            &-img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
