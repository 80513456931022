@import "../../styles/app.scss";

.cta {
    width: $width100;
    padding: 0 28px;
    padding-top: 40px;
    padding-bottom: 50px;
    background-color: $h-green;
    position: relative;
    @include flexRow;
    @include respond-to("medium") {
        padding: 20px 0;
    }
    @include respond-to("large") {
        padding: 40px 0;
    }
    &__icon {
        width: 275px;
        height: auto;
        align-self: center;
        @include respond-to("medium") {
            width: 186px;
        }
        @include respond-to("large") {
            width: 350px;
        }
    }

    &-contents {
        position: absolute;
        align-self: center;
        z-index: 2;
        width: $widthSmall;
        max-width: 319px;
        @include flexCol;
        @include respond-to("medium") {
            max-width: 245px;
        }
        @include respond-to("large") {
            max-width: 460px;
        }
        p {
            color: $h-dark-green;
            text-align: center;
            font-size: 20px;
            margin: 30px 0;
            @extend %font-bold;
            @include respond-to("medium") {
                font-size: 12px;
                line-height: 16px;
                margin: 16px 0;
            }
            @include respond-to("large") {
                font-size: 24px;
                line-height: 30px;
                margin: 30px 0;
            }
        }
    }
    &-centers {
        &-contents{
            @include flexCol;
            gap: 60px;
            @include respond-to("medium") {
                flex-direction: row;
                align-items: center;
                gap: 40px;
            }
            @include respond-to("large") {
                gap: 75px;
            }
        }
        width: $width100;

        padding: 60px 7.5vw;
    
        @include respond-to("medium") {
            padding: 48px;
      
           
        }
        @include respond-to("large") {
            padding: 90px;
        
        }
        &__photo {
            width: $width100;
            align-self: center;
            @include respond-to("medium") {
                width: 40%;
                max-width: 560px;
            }
        }
        &__text {
            @include respond-to("medium") {
                max-width: 480px;
            }
            @include respond-to("large") {
                max-width: 525px;
            }
            p {
                color: $h-dark-green;
                font-size: 16px;
                line-height: 24px;
                @include respond-to("medium") {
                    font-size: 12px;
                    line-height: 16px;
                }
                @include respond-to("large") {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            &-link {
             
                font-size: 16px;
                margin: 0;
             
                @include respond-to("medium") {
                    font-size: 14px;
                }
                @include respond-to("large") {
                    font-size: 27px;
                }
                &-url{
                    position: relative;
                    @include underLineOnHover;
                }
                a{
                    text-decoration: none;
                    color: $h-cont-green;

                    &:hover .cta-centers__text-link__arrow  {
                        transform: translateX(10px);
                        transition: transform .5s linear;
                    }
                }
              
                &__arrow {
                    &:hover {
                        transform: translateX(10px);
                        transition: transform .5s linear;
                    }
                    width: 13px;
                    height: auto;
                    margin-bottom: -1px;
                    @include respond-to("medium") {
                        width: 13px;
                    }
                    @include respond-to("large") {
                       width: 23px;
                       margin-bottom: -3px;
                    }
                }
            }
         
        }
    }
    &__din{
        position: absolute;
        z-index: 2;
        color: $h-dark-green;
        @extend %font-regular;
        right:12px;
        bottom:12px;
        font-size: 12px;
        @include respond-to("medium") {
           font-size: 10px ;
        }
        @include respond-to("large") {
            right:16px;
            bottom:16px;
            font-size: 16px;
        }
    }
}
