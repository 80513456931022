@font-face {
    font-family: 'FS Humana Regular';
    src: url("../assets/fonts/fs-humana-regular.woff2");
}

@font-face {
    font-family: 'FS Humana Bold';
    src: url("../assets/fonts/fs-humana-bold.woff2");
}

@font-face {
    font-family: 'FS Humana Heavy';
    src: url("../assets/fonts/fs-humana-heavy.woff2");
}


%font-regular {
    font-family: "FS Humana Regular";
    font-style: normal;
    font-weight: 400;
}

%font-bold {
    font-family: "FS Humana Bold";
    font-style: normal;
    font-weight: 700;
}

%font-heavy {
    font-family: "FS Humana Heavy";
    font-style: normal;
    font-weight: 900;
}



