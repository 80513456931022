@import "../../styles/app.scss";

.quote {
    width: $width100;
    align-self: center;
    padding: 60px 7.5%;
    margin-top: -2px;
    background: $h-dark-green;
    @include respond-to("medium") {
        padding: 48px 0;
    }
    @include respond-to("large") {
        padding: 90px 0;
    }
    &-contents{
        @include flexCol;
        align-self: center;
    }
    &__qMark {
        align-self: center;
        width: auto;
        height: 14px;
        @include respond-to("large") {
            height: 27.5px;
        }
        &--end{
            transform: rotate(180deg);
        }
    }
    p {
        text-align: center;
        color: $h-green;
        max-width: 438px;
        align-self: center;
        @extend %font-heavy ;
        font-size: 24px;
        @include respond-to("medium") {
            font-size: 18px;
            line-height: 24px;
        }
        @include respond-to("large") {
            max-width: 780px;
            font-size: 36px;
            line-height: 48px;
        }
    }
    &__author{
        color: $h-green;
        align-self: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        @extend %font-regular;
        font-size: 14px;
        margin-top: 36px;
        @include respond-to("medium") {
            margin-top: 26px;
            font-size: 12px;
        }
        @include respond-to("large") {
            margin-top: 60px;
            font-size: 24px;
        }
    }
}
