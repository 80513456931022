@import "../../styles/app.scss";

.logo {
    height: auto;
    align-self: center;
    &-cta {
        width: 163px;
        @include respond-to("medium") {
            width: 137px;
        }
        @include respond-to("large") {
            width: 257px;
        }
        &__path {
            fill: $white;
        }
    }
    &-nav {
        width: 67px;
        @include respond-to("medium") {
            width: 86px;
        }
        @include respond-to("large") {
            width: 163px;
        }

        &__path {
            fill: $white;
         
        }
    }
    &-menu {
        width: 190px;
        &__path {
            fill: $white;
        }
    }
}
